.product-page {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .pp-mblock {
      flex-wrap: wrap !important;
    }
    .pp-mmargin {
      margin: 0 !important;
      padding: 5px 0px 5px 0px;
    }
  }
  .pp-hide-m {
    @media only screen and (min-width: 0px) and (max-width: 1380px) {
      display: none !important;
    }
  }
  .form-control {
    box-shadow: none !important;
  }
  .ftr-time-section {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      justify-content: center !important;
    }
  }
  .pg-section-aatc {
    @media only screen and (min-width: 0px) and (max-width: 1240px) {
      padding-top: 5px !important;
      .pp-tag-style {
        margin-top: 5px !important;
        margin: 0;
      }
    }
  }

  .pp-view-store:hover {
    text-decoration: underline !important;
  }

  .button-margin-dm {
    margin: 0px 10px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      margin: 0;
      margin-top: 5px;
    }
  }

  .pp-main-navigation-next {
    position: absolute;
    right: 0;
    top: 0;
    height: 100% !important;
    display: flex;
    align-items: center;
    z-index: 111;
  }
  .pp-main-navigation-back {
    position: absolute;
    left: 0;
    top: 0;
    height: 100% !important;
    display: flex;
    align-items: center;
    z-index: 111;
  }
  .pp-main-slider {
    position: relative !important;
  }

  .mrl10 {
    margin: 0px 10px;
  }

  .pp-tag-style {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    font-weight: 400;
    height: 40px;
    border-radius: 4px;
  }
  .purchase-info-sub-card {
    .pg-price {
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        padding-top: 5px;
      }
    }
  }
  .purchase-information-card {
    border: 1px solid rgb(213, 217, 217);
    border-radius: 8px;
    padding: 10px;
    position: sticky;
    top: 0px;
    z-index: 111;
    background: #fcfcfc;
  }

  .product-title {
    padding: 5px 0px;
    color: #333638;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.4;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 14px !important;
    }
  }
  .product-brand {
    .value {
      color: #6d6d6d;
      font-weight: 400;
      text-transform: capitalize;
    }
    color: rgba(0, 0, 0, 0.66);
    font-weight: 400;
    padding: 5px 0px 5px 0px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 13px !important;
    }
  }
  .atc-button {
    display: block;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;

    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 111;
      background: #fff;
      justify-content: center;
      margin: 0 !important;
      padding: 8px;
      border: 1px solid #d6caca;
    }

    .atc-button-item {
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        width: 100%;
        margin: 0px 7px !important;
      }
    }

    .atc-button-card {
      display: block;
      margin-bottom: 15px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        margin-bottom: 0px;
      }
    }
    .atc-qty-button {
      width: 100%;
      font-weight: 400 !important;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        white-space: nowrap;
        height: 40px;
      }
    }
    .atc-qty-button-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .qty-dropdown {
      min-width: 200px !important;
      max-width: 100% !important;
    }
  }
  .row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .row-col-p {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 5px;
    }
  }
  .slider-hide-m {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block !important;
    }
  }

  .product-image-thumb {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      .slider__prev {
        display: none;
      }
      .slider__next {
        display: none;
      }
    }
  }
  // .img-list-img {
  //   transition: 0.5s;
  // }
  // .img-list-img:hover {
  //   transform: scale(1.1);
  // }

  .slider .swiper-container1 {
    width: 100%;
    height: 100%;
  }
  .slider .swiper-container2 {
    width: 100%;
    height: 100%;
  }
  .slider__flex {
    display: flex;
    align-items: flex-start;
  }
  .slider__col {
    display: flex;
    flex-direction: column;
    width: 150px;
    margin-right: 32px;
  }
  .slider__prev,
  .slider__next {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .slider__prev:focus,
  .slider__next:focus {
    outline: none;
  }
  .slider__thumbs {
    height: 495px;
    width: 68px !important;
    margin: 0px 5px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: auto !important;
      height: auto;
      margin-bottom: 10px !important;
    }
  }

  .slider__thumbs .slider__image:hover {
    opacity: 1;
  }

  .spec-table-over {
    display: block;
    border: 1px solid rgb(222, 226, 230);
    border-radius: 6px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      white-space: nowrap;
      overflow: auto;
    }
  }

  .slider__images {
    object-fit: contain;
    width: 100%;
    border-radius: 4px;
    display: flex;
    border: 1px solid #eee;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      flex-direction: column-reverse;
    }
  }
  // .slider__images .slider__image img {
  //   transition: 1s;
  // }
  // .slider__images .slider__image:hover img {
  //   transform: scale(1.1);
  // }
  .slider__image {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
  }
  .slider__image_section {
    height: 65px !important;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      height: 40px !important;
      width: 40px !important;
    }
  }
  .slider__image img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    border: 1px solid #eee;
    background: #fff;
    border-radius: 4px;
    padding: 5px;
    min-height: 65px;
    max-height: 65px;
    max-width: 65px;
    min-width: 65px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 2px;
      min-height: 40px;
      max-height: 40px;
      max-width: 40px;
      min-width: 40px;
    }
  }
  @media (max-width: 767.98px) {
    .slider__flex {
      flex-direction: column-reverse;
    }
    .slider__col {
      flex-direction: row;
      align-items: center;
      margin-right: 0;
      margin-top: 24px;
      width: 100%;
    }
    .slider__images {
      width: 100%;
    }
    .slider__thumbs {
      height: auto;
      // width: calc(100% - 96px);
      width: 100%;
      margin: 0 16px;
    }
    .slider__prev,
    .slider__next {
      height: auto;
      width: 32px;
    }
  }

  .cardTitle {
    padding: 10px 0px 10px 0px;
    font-size: 1.2rem;
    text-align: start;
    font-weight: 400;
  }
  .specification-section {
    margin-top: 20px;
    font-size: 13px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 10px !important;
      margin-top: 0px;
    }
  }
  .specification-card {
    display: flex !important;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 5px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding-bottom: 5px !important;
      margin-bottom: 1px !important;
    }
  }
  .Dashboard-books-card {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;
    border-radius: 8px;
  }
  .img-list-card {
    height: 100%;
    min-height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .img-list-img {
    height: 495px;
    object-fit: contain;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    z-index: 2;
    padding: 10px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      height: 215px !important;
    }
  }

  .Dashboard-books-cardb {
    display: flex;
    padding: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
    background: #eee;
    font-size: 13px;
    height: 54px;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 100px !important;
    }
  }
  .img-list-cardb {
    min-height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .purchase-card-list {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 12px;
    }
    svg {
      font-size: 12px;
      margin-right: 10px;
    }
  }
  .select-payment-method {
    padding: 10px;
    background: whitesmoke;
    border: 1px solid rgb(238, 238, 238);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .select-payment-method-select {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .dFlex {
    display: flex;
    align-items: center;
  }
  .padding20 {
    padding: 20px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0 !important;
    }
  }
  .p0 {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0 !important;
    }
  }
  .padding-card-form {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 10px 0px 0px 0px !important;
    }
    padding: 20px 10px 0px 10px;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mr0 {
    margin: 0;
  }
  .purchase-coupon-input {
    width: 100%;
    max-width: 100%;
    input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      height: 40px;
    }
    input:focus {
      box-shadow: none !important;
    }
    button {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      color: #f7ee23 !important;
      background-color: #252734 !important;
      border-color: #252734 !important;
      height: 40px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
    }
    button:hover {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      color: #f7ee23 !important;
      background-color: #252734 !important;
      border-color: #252734 !important;
      height: 40px;
    }
  }
  .complate-underline {
    border-bottom: 4px solid #f7ee23;
    padding-bottom: 10px;
    font-weight: 600;
  }
  .purchase-complete-order-text {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 22px !important;
      text-align: center;
    }
  }

  .payment-method-card {
    background: #eee;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .remove_payment {
      margin-left: 10px;
    }
  }
  .purchase-created-success {
    color: #126735;
    text-align: center;
    font-size: 26px;
    line-height: 1;
  }
  .purchase-created-error {
    color: firebrick;
    font-size: 14px;
    padding-bottom: 40px;
    text-align: center;
  }
  .purchase-created-icon {
    text-align: center;
    padding: 30px;
    svg {
      color: #067a06;
      font-size: 42px;
    }
  }
  .proceed-button {
    width: 100%;
  }
  .product-section {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      margin-top: 0px;
    }
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .purchase-form-card {
    word-break: break-all;
    .purchase-button {
      width: 100%;
    }
  }
  .purchase-info-card {
    word-break: break-all;

    .purchase-infodiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        h4 {
          font-size: 16px !important;
        }
      }
    }

    .purchase-infodivl {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        display: block;
        h4 {
          font-size: 16px !important;
        }
      }
    }
    .purchase-right-footer {
      text-align: center;
      padding: 20px;
      background: #252734;
      h6 {
        color: #f7ee23;
      }
      p {
        color: #f7ee23;
      }
    }
  }
}

.margin-bottom {
  margin-bottom: 15px;
}

.sign-button {
  padding: 20px;
  border: 1px solid #eee;
  text-align: center;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 900px;
}
.s-box {
  min-height: 433px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 30px;
  background-color: #f7f7f9;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
}
label {
  margin-bottom: 3px;
}
.spinner-border {
  width: 1.3rem;
  height: 1.3rem;
  border-width: 0.1em;
}

.success-msg {
  color: #0f5132;
  text-align: center;
  margin-top: 20px;
}
.success-msg svg {
  font-size: 60px;
  border: 1px solid #0f5132;
  border-radius: 30px;
  padding: 10px;
}
.success-msg .title {
  font-size: 15px;
  margin-top: 10px;
}
.success-msg .title1 {
  font-size: 22px;
  margin-top: 10px;
}
.order-md-1 {
  padding: 0 !important;
}

.form-control-custom {
  height: 50px !important;
  padding: 11px !important;
  border: 1px solid #eee !important;
}
.form-control-custom:focus {
  box-shadow: none !important;
}

.coloredButton {
  background: #191a1a !important;
  color: #fcfcfc !important;
  border: 1px solid #191a1a !important;
  height: 50px;
}
.inquiry-form-button {
  color: #fcfcfc !important;
  background-color: #191a1a !important;
  border-color: #191a1a !important;
  width: 100%;
  height: 46px;
}
// .fixedBottom {
//   @media only screen and (min-width: 0px) and (max-width: 992px) {
//     position: fixed;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     z-index: 999;
//   }
// }
.accept-text {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    font-size: 11px;
  }
  font-size: 14px;
}

.expiry-date-card {
  width: 50% !important;
  max-width: 50% !important;
  flex: 50% !important;
}
.csv-card {
  width: 50% !important;
  max-width: 50% !important;
  flex: 50% !important;
}

.ant-notification-notice {
  width: 440px !important;
}

tr:nth-child(even) {
  background-color: #f5f5f5 !important;
}
.qty-dropdown {
  min-height: 0px;
  max-height: 200px;
  overflow: auto;
}
